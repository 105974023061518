<template>
  <b-img :src="appLogoImage" alt="logo" width="60" />
</template>

<script>
export default {
  data: () => ({
    appLogoImage: require('@/assets/images/logo/logo.svg'),
  }),
};
</script>
